/*
<ai_context>
LandingPage.jsx
Converting the landing page from HTML to a proper React component with Shadcn UI components.
</ai_context>
*/
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import '../styles/LandingPage.css';

// Import Shadcn UI components
import { Button } from '../components/ui/button';
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from '../components/ui/card';
import { Input } from '../components/ui/input';
import { Switch } from '../components/ui/switch';
import { Badge } from '../components/ui/badge';

function LandingPage() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useEffect(() => {
    // Hide the old landing content if it exists
    const landingContent = document.getElementById('landing-content');
    if (landingContent) {
      landingContent.style.display = 'none';
    }

    // Add scroll animation
    const handleScroll = () => {
      const elements = document.querySelectorAll('.animate-on-scroll');
      elements.forEach(element => {
        const elementPosition = element.getBoundingClientRect().top;
        const windowHeight = window.innerHeight;
        if (elementPosition < windowHeight - 100) {
          element.classList.add('animate-visible');
        }
      });
    };

    window.addEventListener('scroll', handleScroll);
    handleScroll(); // Initial check

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);


  return (
    <div className="landing-page">
      {/* HEADER / NAV */}
      <header className="fixed top-0 left-0 right-0 z-[100] border-b backdrop-blur-md bg-background/80 shadow-sm">
        <div className="mx-auto flex max-w-screen-xl items-center justify-between px-4 py-3">
          {/* Logo */}
          <Link to="/" className="flex items-center gap-1 md:gap-2">
            <img src="/group-1.svg" alt="Corrige Logo" className="h-8 w-auto md:h-10" />
            <span className="text-lg md:text-2xl font-semibold">Corrige</span>
          </Link>

          {/* Navigation - Desktop (changed from md:flex to lg:flex) */}
          <nav className="hidden items-center space-x-8 lg:flex">
            <a href="#benefices" className="text-base font-medium text-muted-foreground hover:text-primary">Bénéfices</a>
            <a href="#demo" className="text-base font-medium text-muted-foreground hover:text-primary">Démo</a>
            <a href="#tarification" className="text-base font-medium text-muted-foreground hover:text-primary">Tarification</a>
            <a href="#faq" className="text-base font-medium text-muted-foreground hover:text-primary">FAQ</a>
            <a href="#contact" className="text-base font-medium text-muted-foreground hover:text-primary">Contactez-nous</a>
          </nav>

          {/* Mobile menu button (changed from md:hidden to lg:hidden) */}
          <button 
            className="lg:hidden p-2 rounded-md hover:bg-accent"
            onClick={() => setIsMenuOpen(!isMenuOpen)}
          >
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="w-6 h-6">
              {isMenuOpen 
                ? <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                : <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
              }
            </svg>
          </button>

          {/* Top-right button (changed from md:inline-flex to lg:inline-flex) */}
          <Button asChild className="hidden lg:inline-flex" size="lg">
            <Link to="/login">
              ESSAYER MAINTENANT
            </Link>
          </Button>
        </div>

        {/* Mobile menu dropdown (changed from md:hidden to lg:hidden) */}
        {isMenuOpen && (
          <div className="lg:hidden bg-background/95 backdrop-blur-md px-4 pt-2 pb-4 border-b border-border">
            <nav className="flex flex-col space-y-4">
              <a 
                href="#benefices" 
                className="text-base font-medium text-muted-foreground hover:text-primary"
                onClick={() => setIsMenuOpen(false)}
              >
                Bénéfices
              </a>
              <a 
                href="#demo" 
                className="text-base font-medium text-muted-foreground hover:text-primary"
                onClick={() => setIsMenuOpen(false)}
              >
                Démo
              </a>
              <a 
                href="#tarification" 
                className="text-base font-medium text-muted-foreground hover:text-primary"
                onClick={() => setIsMenuOpen(false)}
              >
                Tarification
              </a>
              <a 
                href="#faq" 
                className="text-base font-medium text-muted-foreground hover:text-primary"
                onClick={() => setIsMenuOpen(false)}
              >
                FAQ
              </a>
              <a 
                href="#contact" 
                className="text-base font-medium text-muted-foreground hover:text-primary"
                onClick={() => setIsMenuOpen(false)}
              >
                Contactez-nous
              </a>
              <Button asChild size="sm" className="w-full">
                <Link to="/login" onClick={() => setIsMenuOpen(false)}>
                  ESSAYER MAINTENANT
                </Link>
              </Button>
            </nav>
          </div>
        )}
      </header>

      {/* HERO SECTION */}
      <section className="relative bg-gradient-to-br from-purple-100 to-indigo-100 pt-32 pb-16 md:pt-44 md:pb-24 overflow-hidden">
        <div className="absolute inset-0 bg-grid-pattern opacity-10"></div>
        <div className="mx-auto flex max-w-screen-xl flex-col-reverse gap-12 items-center md:items-stretch px-6 sm:px-8 md:px-10 md:flex-row md:gap-4 lg:gap-6">
          <div className="w-full md:w-1/2 flex flex-col items-center md:items-start justify-center animate-hero-content">
            <h1 className="mb-6 text-4xl font-bold leading-snug md:text-5xl lg:text-6xl text-center md:text-left">
              <span className="inline-block mb-2">Moins de temps à corriger,</span><br/>
              <span className="inline-block pb-1 bg-clip-text text-transparent bg-gradient-to-r from-purple-500 to-indigo-500">plus à enseigner.</span>
            </h1>
            <p className="mb-8 text-xl text-center md:text-left">
              L'outil IA qui vous sauve des heures de correction.
            </p>
            <div className="flex flex-wrap gap-4 justify-center md:justify-start">
              <Button asChild size="lg" className="rounded-full shadow-md hover:shadow-lg px-8">
                <Link to="/login">
                  Démarrer un essai gratuit
                </Link>
              </Button>
              <Button asChild size="lg" className="rounded-full bg-green-500 hover:bg-green-700 text-white shadow-md hover:shadow-lg px-8">
                <a href="#demo">
                  Voir la démo
                </a>
              </Button>
            </div>
          </div>
          <div className="w-full md:w-1/2 flex justify-center md:justify-center animate-hero-image">
            <img 
              src="/paper-and-checkmark.svg" 
              alt="Papers with check" 
              className="w-full max-w-[300px] sm:max-w-[400px] md:max-w-[450px] h-auto max-h-[300px] sm:max-h-[400px] md:max-h-[400px] object-contain drop-shadow-xl"
            />
          </div>
        </div>
      </section>

      {/* Gradient divider */}
      <div className="h-16 bg-gradient-to-b from-indigo-100 to-white"></div>

      {/* INTRO / BENEFITS TEXT */}
      <section id="benefices" className="py-20 text-center animate-on-scroll" style={{opacity: 0, transform: 'translateY(20px)'}}>
        <div className="mx-auto max-w-screen-lg px-4">
          <h2 className="mb-6 text-3xl font-bold md:text-4xl lg:text-5xl">
            La correction d'examens simplifiée pour les professeurs du Québec
          </h2>
          <p className="text-xl text-muted-foreground max-w-3xl mx-auto">
            Notre outil va transformer la façon dont vous corrigez les examens.
            <br />
            L'IA ne se contente pas de simplifier vos tâches,
            elle les rend plus rapides, plus précises. 
            <br />
            Elle vous permet de vous concentrer sur ce qui compte vraiment : <strong className="text-primary">L'enseignement</strong>.
          </p>
        </div>
      </section>

      {/* FLEXIBLE / ACCESSIBLE BANNER */}
      <section className="relative py-24 text-center overflow-hidden animate-on-scroll" style={{opacity: 0, transform: 'translateY(20px)'}}>
        {/* More widely scattered bubbles with more visible solid purple ones */}
        {/* Outlined circles - spread widely */}
        <div className="absolute top-[2%] left-[3%] h-16 w-16 md:h-20 md:w-20 rounded-full border-3 border-purple-300 opacity-60 animate-pulse"></div>
        <div className="absolute top-[6%] right-[4%] h-12 w-12 md:h-16 md:w-16 rounded-full border-2 border-purple-200 opacity-70 animate-pulse"></div>
        <div className="absolute top-[22%] left-[18%] h-8 w-8 md:h-10 md:w-10 rounded-full border-2 border-purple-300 opacity-50 animate-pulse"></div>
        <div className="absolute top-[15%] right-[22%] h-10 w-10 md:h-12 md:w-12 rounded-full border-2 border-purple-300 opacity-50 animate-pulse"></div>
        
        <div className="absolute top-[48%] left-[6%] h-14 w-14 md:h-18 md:w-18 rounded-full border-3 border-purple-200 opacity-60 animate-pulse"></div>
        <div className="absolute top-[45%] left-[2%] h-10 w-10 md:h-14 md:w-14 rounded-full border-2 border-purple-300 opacity-60 animate-pulse" style={{animationDuration: '6s'}}></div>
        <div className="absolute top-[38%] right-[45%] h-6 w-6 md:h-8 md:w-8 rounded-full border-1 border-purple-300 opacity-50 animate-pulse"></div>
        
        <div className="absolute bottom-[12%] left-[15%] h-12 w-12 md:h-16 md:w-16 rounded-full border-2 border-purple-200 opacity-60 animate-pulse"></div>
        <div className="absolute bottom-[5%] right-[20%] h-16 w-16 md:h-20 md:w-20 rounded-full border-3 border-purple-200 opacity-50 animate-pulse"></div>
        <div className="absolute bottom-[28%] left-[28%] h-9 w-9 md:h-12 md:w-12 rounded-full border-2 border-purple-300 opacity-50 animate-pulse"></div>
        <div className="absolute bottom-[18%] right-[35%] h-7 w-7 md:h-9 md:w-9 rounded-full border-2 border-purple-200 opacity-60 animate-pulse" style={{animationDuration: '5s'}}></div>
        
        {/* Solid purple bubbles - more prominent and scattered */}
        <div className="absolute top-[12%] left-[42%] h-8 w-8 md:h-10 md:w-10 rounded-full bg-purple-300/40 animate-pulse"></div>
        <div className="absolute bottom-[32%] right-[12%] h-10 w-10 md:h-12 md:w-12 rounded-full bg-purple-200/50 animate-pulse"></div>
        <div className="absolute top-[62%] left-[12%] h-7 w-7 md:h-9 md:w-9 rounded-full bg-purple-300/30 animate-pulse"></div>
        <div className="absolute bottom-[8%] left-[38%] h-6 w-6 md:h-8 md:w-8 rounded-full bg-purple-200/40 animate-pulse"></div>
        
        <div className="absolute top-[42%] right-[30%] h-9 w-9 md:h-11 md:w-11 rounded-full bg-purple-200/40 animate-pulse"></div>
        <div className="absolute top-[28%] left-[8%] h-5 w-5 md:h-7 md:w-7 rounded-full bg-purple-300/50 animate-pulse"></div>
        <div className="absolute bottom-[45%] left-[22%] h-8 w-8 md:h-10 md:w-10 rounded-full bg-purple-200/30 animate-pulse"></div>
        <div className="absolute top-[72%] right-[25%] h-6 w-6 md:h-8 md:w-8 rounded-full bg-purple-300/40 animate-pulse"></div>
        
        {/* Extra tiny bubbles for additional scattered feel */}
        <div className="absolute top-[34%] right-[18%] h-4 w-4 md:h-6 md:w-6 rounded-full bg-purple-200/20 animate-pulse"></div>
        <div className="absolute bottom-[22%] right-[50%] h-3 w-3 md:h-5 md:w-5 rounded-full bg-purple-300/30 animate-pulse"></div>
        <div className="absolute top-[58%] left-[48%] h-4 w-4 md:h-5 md:w-5 rounded-full bg-purple-200/30 animate-pulse"></div>
        
        <div className="relative mx-auto max-w-screen-lg px-4 z-10">
          <div className="rounded-2xl bg-gradient-to-br from-purple-500/90 to-indigo-600/90 backdrop-blur-sm p-10 shadow-xl border border-purple-400/20 animate-card-appear animate-on-scroll" style={{opacity: 0, transform: 'translateY(20px) scale(0.97)'}}>
            <h3 className="mb-5 text-3xl font-bold text-white md:text-4xl">
              Flexible et accessible
            </h3>
            <p className="mx-auto max-w-2xl text-lg text-white/90 font-medium">
              Choisissez un plan adapté à vos besoins, avec un essai gratuit de 30 jours
              pour tester l'outil sans engagement
            </p>
            <div className="mt-8">
              <Button asChild size="lg" className="rounded-full bg-white text-purple-700 shadow-md hover:shadow-xl hover:bg-gray-100 transition-all duration-300 px-8 font-medium">
                <a href="#tarification">
                  Voir les tarifs
                </a>
              </Button>
            </div>
          </div>
        </div>
      </section>

      {/* STEPS SECTION */}
      <section className="py-24 text-center bg-gradient-to-b from-white to-purple-50/30">
        <div className="mx-auto max-w-screen-lg px-4">
          <h2 className="mb-14 text-3xl font-bold md:text-4xl lg:text-5xl animate-on-scroll" style={{opacity: 0, transform: 'translateY(20px)'}}>
            C'est aussi simple que <span className="text-transparent bg-clip-text bg-gradient-to-r from-purple-600 to-indigo-600">1, 2, 3!</span>
          </h2>
          
          <div className="relative mx-auto">
            {/* Steps container */}
            <div className="max-w-4xl mx-auto grid gap-14 md:grid-cols-3 md:gap-0">
              {/* Step 1 */}
              <div className="relative md:pr-8 animate-on-scroll" style={{opacity: 0, transform: 'translateY(20px)', transitionDelay: '0.1s'}}>
                {/* Number circle */}
                <div className="w-14 h-14 bg-gradient-to-r from-purple-500 to-indigo-600 rounded-full flex items-center justify-center text-white text-xl font-bold shadow-md mx-auto mb-4 z-10 relative">
                  1
                </div>
                
                {/* Connecting line (right) - only on tablet and up */}
                <div className="hidden md:block absolute top-7 left-[calc(50%+35px)] right-0 h-0.5 bg-gradient-to-r from-indigo-500 to-indigo-300 z-0"></div>
                
                {/* Card */}
                <div className="rounded-2xl bg-gradient-to-br from-purple-100 to-indigo-100 p-6 shadow-md hover:shadow-lg transition-all duration-300 border border-purple-200/50 h-[320px]">
                  <div className="mb-9"></div> {/* Top spacing to push down content */}
                  <img
                    src="/untitled-10.svg"
                    alt="Upload Icon"
                    className="h-16 w-auto mx-auto mb-5"
                  />
                  <h4 className="text-lg font-semibold text-gray-800 mb-4">Importez</h4>
                  <p className="text-base text-muted-foreground mx-auto max-w-[90%]">
                    Importez les soumissions d'examens depuis votre ordinateur ou par
                    Google Classroom
                  </p>
                </div>
              </div>
              
              {/* Step 2 */}
              <div className="relative md:px-8 animate-on-scroll" style={{opacity: 0, transform: 'translateY(20px)', transitionDelay: '0.2s'}}>
                {/* Number circle */}
                <div className="w-14 h-14 bg-gradient-to-r from-purple-500 to-indigo-600 rounded-full flex items-center justify-center text-white text-xl font-bold shadow-md mx-auto mb-4 z-10 relative">
                  2
                </div>
                
                {/* Connecting lines (left and right) - only on tablet and up */}
                <div className="hidden md:block absolute top-7 left-0 right-[calc(50%+35px)] h-0.5 bg-gradient-to-r from-indigo-300 to-indigo-500 z-0"></div>
                <div className="hidden md:block absolute top-7 left-[calc(50%+35px)] right-0 h-0.5 bg-gradient-to-r from-indigo-500 to-indigo-300 z-0"></div>
                
                {/* Card */}
                <div className="rounded-2xl bg-gradient-to-br from-purple-100 to-indigo-100 p-6 shadow-md hover:shadow-lg transition-all duration-300 border border-purple-200/50 h-[320px]">
                  <div className="mb-9"></div> {/* Top spacing to push down content */}
                  <img
                    src="/untitled-13.svg"
                    alt="Document with check Icon"
                    className="h-16 w-auto mx-auto mb-5"
                  />
                  <h4 className="text-lg font-semibold text-gray-800 mb-4">Soumettez</h4>
                  <p className="text-base text-muted-foreground mx-auto max-w-[90%]">
                    Soumettez une copie corrigée de l'examen comme référence pour l'IA
                  </p>
                </div>
              </div>
              
              {/* Step 3 */}
              <div className="relative md:pl-8 animate-on-scroll" style={{opacity: 0, transform: 'translateY(20px)', transitionDelay: '0.3s'}}>
                {/* Number circle */}
                <div className="w-14 h-14 bg-gradient-to-r from-purple-500 to-indigo-600 rounded-full flex items-center justify-center text-white text-xl font-bold shadow-md mx-auto mb-4 z-10 relative">
                  3
                </div>
                
                {/* Connecting line (left) - only on tablet and up */}
                <div className="hidden md:block absolute top-7 left-0 right-[calc(50%+35px)] h-0.5 bg-gradient-to-r from-indigo-300 to-indigo-500 z-0"></div>
                
                {/* Card */}
                <div className="rounded-2xl bg-gradient-to-br from-purple-100 to-indigo-100 p-6 shadow-md hover:shadow-lg transition-all duration-300 border border-purple-200/50 h-[320px]">
                  <div className="mb-9"></div> {/* Top spacing to push down content */}
                  <img
                    src="/untitled-15.svg"
                    alt="Check Icon"
                    className="h-16 w-auto mx-auto mb-5"
                  />
                  <h4 className="text-lg font-semibold text-gray-800 mb-4">Recevez</h4>
                  <p className="text-base text-muted-foreground mx-auto max-w-[90%]">
                    Recevez les résultats pour chaque élève en quelques instants
                  </p>
                </div>
              </div>
            </div>
          </div>
          
          <div className="mt-14 animate-on-scroll" style={{opacity: 0, transform: 'translateY(20px)'}}>
            <Button asChild size="lg" className="rounded-full bg-gradient-to-r from-purple-600 to-indigo-600 hover:from-purple-700 hover:to-indigo-700 text-white shadow-md hover:shadow-lg px-8 py-5 text-lg transition-all duration-300">
              <Link to="/login">
                Essayer tout de suite
              </Link>
            </Button>
          </div>
        </div>
      </section>

      {/* INTEGRATION SECTION */}
      <section className="bg-gradient-to-br from-accent/30 to-background py-24 text-center animate-on-scroll" style={{opacity: 0, transform: 'translateY(20px)'}}>
        <div className="mx-auto max-w-screen-lg px-4">
          <h3 className="mb-6 text-3xl font-bold md:text-4xl">
            Importez vos examens facilement
          </h3>
          <p className="mx-auto mb-16 max-w-2xl text-lg text-muted-foreground">
            Choisissez la méthode qui vous convient le mieux pour importer vos examens
          </p>
          
          <div className="grid gap-8 md:grid-cols-3">
            {/* Google Classroom Integration */}
            <div className="group rounded-2xl bg-white p-6 shadow-md hover:shadow-lg transition-all duration-300 border border-border">
              <div className="mb-6 flex h-16 items-center justify-center">
                <img
                  src="/google-classroom-logo.png"
                  alt="Google Classroom"
                  className="h-12 w-auto drop-shadow transition-transform duration-300 group-hover:scale-110"
                />
              </div>
              <h4 className="mb-3 text-xl font-semibold">Google Classroom</h4>
              <p className="text-muted-foreground">
                Importez directement depuis vos devoirs Google Classroom en quelques clics
              </p>
            </div>

            {/* Google Drive Integration */}
            <div className="group rounded-2xl bg-white p-6 shadow-md hover:shadow-lg transition-all duration-300 border border-border">
              <div className="mb-6 flex h-16 items-center justify-center">
                <img
                  src="/google-drive-logo-wine.png"
                  alt="Google Drive"
                  className="h-12 w-auto drop-shadow transition-transform duration-300 group-hover:scale-110"
                />
              </div>
              <h4 className="mb-3 text-xl font-semibold">Google Drive</h4>
              <p className="text-muted-foreground">
                Sélectionnez les examens directement depuis votre Google Drive
              </p>
            </div>

            {/* Manual Upload */}
            <div className="group rounded-2xl bg-white p-6 shadow-md hover:shadow-lg transition-all duration-300 border border-border">
              <div className="mb-6 flex h-16 items-center justify-center">
                <div className="rounded-full bg-purple-100 p-3">
                  <img
                    src="/untitled-10.svg"
                    alt="Upload icon"
                    className="h-10 w-auto drop-shadow transition-transform duration-300 group-hover:scale-110"
                  />
                </div>
              </div>
              <h4 className="mb-3 text-xl font-semibold">Téléversement Manuel</h4>
              <p className="text-muted-foreground">
                Glissez-déposez ou sélectionnez les fichiers depuis votre ordinateur
              </p>
            </div>
          </div>

          <div className="mt-12">
            <Button asChild variant="outline" size="lg" className="rounded-full">
              <Link to="/login">
                Commencer l'importation
              </Link>
            </Button>
          </div>
        </div>
      </section>

      {/* DEMO VIDEO SECTION */}
      <section id="demo" className="py-24 text-center bg-background">
        <div className="mx-auto max-w-screen-lg px-4">
          <h2 className="mb-6 text-3xl font-bold md:text-4xl lg:text-5xl animate-on-scroll" style={{opacity: 0, transform: 'translateY(20px)'}}>
            Découvrez notre outil en action
          </h2>
          <p className="mx-auto mb-12 max-w-2xl text-xl text-muted-foreground animate-on-scroll" style={{opacity: 0, transform: 'translateY(20px)'}}>
            Regardez cette démo rapide pour découvrir comment notre outil simplifie la
            correction d'examens en quelques clics.
          </p>
          <div className="relative h-0 overflow-hidden pb-[56.25%] rounded-xl shadow-2xl animate-on-scroll" style={{opacity: 0, transform: 'translateY(20px)'}}>
            <iframe
              className="absolute top-0 left-0 h-full w-full"
              width="560"
              height="315"
              src="https://www.youtube.com/embed/2RP2K6r4tMU"
              title="YouTube video"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
          <div className="mt-16 animate-on-scroll" style={{opacity: 0, transform: 'translateY(20px)'}}>
            <Button asChild size="lg" className="rounded-full shadow-md hover:shadow-lg px-8">
              <Link to="/login">
                Démarrer un essai gratuit
              </Link>
            </Button>
          </div>
        </div>
      </section>

      {/* PRICING SECTION */}
      <section id="tarification" className="py-24 bg-gradient-to-br from-accent/30 to-background">
        <div className="mx-auto max-w-screen-xl px-4">
          <div className="text-center mb-16">
            <h2 className="mb-6 text-3xl font-bold md:text-4xl lg:text-5xl animate-on-scroll" style={{opacity: 0, transform: 'translateY(20px)'}}>
              Tarification simple et transparente
            </h2>
            <p className="mx-auto mb-12 max-w-2xl text-xl text-muted-foreground animate-on-scroll" style={{opacity: 0, transform: 'translateY(20px)'}}>
              Profitez d'une correction rapide, précise et accessible, tout en gagnant du temps
              pour vous concentrer sur l'enseignement de vos élèves.
            </p>

            {/* Pricing note */}
            <div className="mb-16 animate-on-scroll" style={{opacity: 0, transform: 'translateY(20px)'}}>
              <p className="text-lg text-muted-foreground">
                Des tarifs flexibles adaptés à vos besoins
              </p>
            </div>
          </div>

          <div className="grid gap-8 lg:grid-cols-3">
            {/* FREE PLAN */}
            <div className="animate-on-scroll" style={{opacity: 0, transform: 'translateY(20px)', transitionDelay: '0.1s'}}>
              <Card className="relative h-full transition-all duration-300 hover:shadow-lg">
                <CardHeader className="bg-gradient-to-br from-violet-400 to-violet-600 text-white rounded-t-lg">
                  <div className="p-6 -mx-6 -mt-6 text-white rounded-t-lg">
                    <CardTitle className="text-2xl font-bold mb-2 text-white">Gratuit</CardTitle>
                    <CardDescription className="text-base text-white/90">
                      Pour les enseignants qui veulent découvrir Corrige
                    </CardDescription>
                  </div>
                  <div className="mt-4 flex items-baseline">
                    <span className="text-5xl font-bold">$0</span>
                    <span className="ml-1 text-base font-bold">/ mois</span>
                  </div>
                </CardHeader>
                <CardContent className="pt-6">
                  <ul className="space-y-4">
                    <li className="flex items-center">
                      <svg className="h-5 w-5 text-green-500 flex-shrink-0" viewBox="0 0 24 24" fill="none" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2.5" d="M5 13l4 4L19 7"/>
                      </svg>
                      <span className="ml-3 text-base">15 copies pour les 30 premiers jours</span>
                    </li>
                    <li className="flex items-center">
                      <svg className="h-5 w-5 text-green-500 flex-shrink-0" viewBox="0 0 24 24" fill="none" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2.5" d="M5 13l4 4L19 7"/>
                      </svg>
                      <span className="ml-3 text-base">Analyse IA et annotations</span>
                    </li>
                  </ul>
                </CardContent>
                <CardFooter className="mt-auto">
                  <Button asChild className="w-full rounded-full bg-violet-500 hover:bg-violet-600 transition-colors" size="lg">
                    <Link to="/login">
                      Démarrer gratuitement
                    </Link>
                  </Button>
                </CardFooter>
              </Card>
            </div>

            {/* ESSENTIAL PLAN */}
            <div className="animate-on-scroll" style={{opacity: 0, transform: 'translateY(20px)', transitionDelay: '0.2s'}}>
              <Card className="relative h-full transition-all duration-300 hover:shadow-lg border-2 border-primary">
                <div className="absolute -top-4 inset-x-0 flex justify-center">
                  <Badge className="bg-primary text-white border-0 px-6 py-1 text-sm font-medium shadow-sm">
                    Populaire
                  </Badge>
                </div>
                <CardHeader className="bg-gradient-to-br from-violet-600 to-violet-800 text-white rounded-t">
                  <div className="p-6 -mx-6 -mt-6 text-white rounded-t-lg">
                    <CardTitle className="text-2xl font-bold mb-2 text-white">Personnel</CardTitle>
                    <CardDescription className="text-base text-white/90">
                      Pour les enseignants qui veulent accélérer la correction
                    </CardDescription>
                  </div>
                  <div className="mt-4 flex items-baseline">
                    <span className="text-3xl font-bold">Tarif spécial</span>
                  </div>
                  <p className="mt-1 text-sm text-green-300 font-medium">Contactez-nous pour les détails</p>
                </CardHeader>
                <CardContent className="pt-6">
                  <ul className="space-y-4">
                    <li className="flex items-center">
                      <svg className="h-5 w-5 text-green-500 flex-shrink-0" viewBox="0 0 24 24" fill="none" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2.5" d="M5 13l4 4L19 7"/>
                      </svg>
                      <span className="ml-3 text-base">150 copies/mois</span>
                    </li>
                    <li className="flex items-center">
                      <svg className="h-5 w-5 text-green-500 flex-shrink-0" viewBox="0 0 24 24" fill="none" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2.5" d="M5 13l4 4L19 7"/>
                      </svg>
                      <span className="ml-3 text-base">Annotations intelligentes</span>
                    </li>
                    <li className="flex items-center">
                      <svg className="h-5 w-5 text-green-500 flex-shrink-0" viewBox="0 0 24 24" fill="none" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2.5" d="M5 13l4 4L19 7"/>
                      </svg>
                      <span className="ml-3 text-base">Support 24/7</span>
                    </li>
                  </ul>
                </CardContent>
                <CardFooter className="mt-auto">
                  <Button asChild className="w-full rounded-full bg-violet-700 hover:bg-violet-800 transition-colors shadow-sm" size="lg">
                    <Link to="/login">
                      Démarrer avec le Plan Personnel
                    </Link>
                  </Button>
                </CardFooter>
              </Card>
            </div>

            {/* INSTITUTION PLAN */}
            <div className="animate-on-scroll" style={{opacity: 0, transform: 'translateY(20px)', transitionDelay: '0.3s'}}>
              <Card className="relative h-full transition-all duration-300 hover:shadow-lg">
                <CardHeader className="bg-gradient-to-br from-violet-800 to-violet-900 text-white rounded-t-lg">
                  <div className="p-6 -mx-6 -mt-6 text-white rounded-t-lg">
                    <CardTitle className="text-2xl font-bold mb-2 text-white">Institution</CardTitle>
                    <CardDescription className="text-base text-white/90">Pour les écoles</CardDescription>
                  </div>
                  <div className="mt-4">
                    <span className="text-2xl font-bold">Plan Personnalisé</span>
                  </div>
                </CardHeader>
                <CardContent className="pt-6">
                  <ul className="space-y-4">
                    <li className="flex items-center">
                      <svg className="h-5 w-5 text-green-500 flex-shrink-0" viewBox="0 0 24 24" fill="none" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2.5" d="M5 13l4 4L19 7"/>
                      </svg>
                      <span className="ml-3 text-base">Formation et intégration professionnelle</span>
                    </li>
                    <li className="flex items-center">
                      <svg className="h-5 w-5 text-green-500 flex-shrink-0" viewBox="0 0 24 24" fill="none" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2.5" d="M5 13l4 4L19 7"/>
                      </svg>
                      <span className="ml-3 text-base">Support prioritaire par email et vidéo</span>
                    </li>
                    <li className="flex items-center">
                      <svg className="h-5 w-5 text-green-500 flex-shrink-0" viewBox="0 0 24 24" fill="none" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2.5" d="M5 13l4 4L19 7"/>
                      </svg>
                      <span className="ml-3 text-base">Tarifs dégressifs selon le nombre d'enseignants</span>
                    </li>
                  </ul>
                </CardContent>
                <CardFooter className="mt-auto">
                  <Button asChild className="w-full rounded-full bg-violet-900 hover:bg-violet-950 transition-colors" size="lg">
                    <Link to="/login">
                      Obtenir Corrige pour votre école
                    </Link>
                  </Button>
                </CardFooter>
              </Card>
            </div>
          </div>
        </div>
      </section>

      {/* FAQ SECTION */}
      <section id="faq" className="py-24 bg-gradient-to-br from-background to-accent/30">
        <div className="mx-auto max-w-screen-lg px-4">
          <h2 className="mb-12 text-3xl font-bold md:text-4xl lg:text-5xl text-center animate-on-scroll" style={{opacity: 0, transform: 'translateY(20px)'}}>
            Foire aux questions
          </h2>
          
          <div className="grid gap-6 md:grid-cols-2 animate-on-scroll" style={{opacity: 0, transform: 'translateY(20px)'}}>
            {/* Question 1 */}
            <div className="rounded-xl bg-white p-6 shadow-md hover:shadow-lg transition-all duration-300 border border-border">
              <h3 className="text-xl font-bold mb-3 text-primary">Corrige, c'est quoi exactement ?</h3>
              <p className="text-muted-foreground">
              <strong>Corrige</strong> est une plateforme d'aide à la correction utilisant l'intelligence artificielle, conçue spécifiquement pour les enseignants.
              <br /><br />
              Elle vous permet d'<strong>importer facilement</strong> les devoirs et les soumissions de vos élèves, d'obtenir des <strong>suggestions de notes</strong> et de justifications générées par l'IA, et de réviser le tout rapidement.
              <br /><br />
              L'objectif est de vous faire <strong>gagner un temps précieux</strong> sur la correction pour que vous puissiez vous concentrer davantage sur l'enseignement et l'accompagnement de vos élèves.
              </p>
            </div>
            
            {/* Question 2 */}
            <div className="rounded-xl bg-white p-6 shadow-md hover:shadow-lg transition-all duration-300 border border-border">
              <h3 className="text-xl font-bold mb-3 text-primary">À qui s'adresse Corrige ?</h3>
              <p className="text-muted-foreground">
              Corrige a été pensé et développé spécifiquement pour les <strong>enseignants et enseignantes</strong> des écoles primaires et secondaires du <strong>Québec</strong>.
              <br /><br /> 
              Notre outil vise à <strong>simplifier votre processus de correction</strong> tout en respectant les particularités de notre système éducatif.
              </p>
            </div>
            
            {/* Question 3 */}
            <div className="rounded-xl bg-white p-6 shadow-md hover:shadow-lg transition-all duration-300 border border-border">
              <h3 className="text-xl font-bold mb-3 text-primary">Est-ce compliqué à utiliser ? Ai-je besoin de compétences techniques ?</h3>
              <p className="text-muted-foreground">
              <strong>Absolument pas !</strong> Corrige est conçu pour être <strong>intuitif et simple d'utilisation</strong>, même sans compétences techniques avancées.
              <br /><br />
              Si vous savez utiliser des outils comme Google Classroom ou Google Forms, vous serez à l'aise avec Corrige.
              </p>
            </div>
            
            {/* Question 4 */}
            <div className="rounded-xl bg-white p-6 shadow-md hover:shadow-lg transition-all duration-300 border border-border">
              <h3 className="text-xl font-bold mb-3 text-primary">Comment fonctionne la correction par IA ?</h3>
              <p className="text-muted-foreground">
              <strong>C'est simple :</strong>
              <br /><br />
              <strong>1.</strong> Vous importez le devoir et les réponses des élèves.
              <br />
              <strong>2.</strong> Vous fournissez la réponse attendue (le corrigé) pour chaque question.
              <br />
              <strong>3.</strong> Notre IA analyse chaque réponse d'élève en la comparant à votre corrigé et au contexte de la question.
              <br />
              <strong>4.</strong> Elle vous propose ensuite une <strong>note</strong>, une <strong>justification détaillée</strong>, ainsi qu'un <strong>indice de confiance</strong>.
              <br />
              <strong>5.</strong> Vous n'avez plus qu'à réviser et valider !
              </p>
            </div>
            
            {/* Question 5 */}
            <div className="rounded-xl bg-white p-6 shadow-md hover:shadow-lg transition-all duration-300 border border-border">
              <h3 className="text-xl font-bold mb-3 text-primary">Puis-je modifier les notes proposées par l'IA ?</h3>
              <p className="text-muted-foreground">
              <strong>Oui, absolument !</strong> Vous avez toujours le <strong>contrôle final</strong>. Corrige est un assistant ; vous pouvez facilement réviser, modifier et confirmer chaque note suggérée par l'IA.
              <br /><br />
              Mieux encore : lorsque vous ajustez une note, vous pouvez indiquer pourquoi (via notre fonction <strong>"Mémoire de Correction"</strong>). 
              <br /><br />
              L'IA <strong>apprend de votre style de correction</strong> et utilise vos ajustements précédents pour affiner ses futures suggestions, afin qu'elles correspondent de mieux en mieux à vos attentes spécifiques.
              </p>
            </div>
            
            {/* Question 6 */}
            <div className="rounded-xl bg-white p-6 shadow-md hover:shadow-lg transition-all duration-300 border border-border">
              <h3 className="text-xl font-bold mb-3 text-primary">Qu'advient-il des données de mes élèves ? Sont-elles en sécurité ?</h3>
              <p className="text-muted-foreground">
              La <strong>sécurité et la confidentialité</strong> de vos données et de celles de vos élèves sont notre <strong>priorité absolue</strong>. Nous utilisons des mesures de sécurité conformes aux standards de l'industrie, incluant le chiffrement et des contrôles d'accès stricts.
              <br /><br />
              De plus, toutes les informations confidentielles que vous nous confiez sont <strong>hébergées et traitées exclusivement au Québec</strong>.
              <br /><br />
              Vos données (corrigés, réponses d'élèves) ne sont utilisées que pour effectuer la correction de vos devoirs et <strong>ne sont jamais vendues</strong> ni utilisées pour entraîner des modèles d'IA généraux.
              <br /><br />
              Pour plus de détails, nous vous invitons à consulter notre Politique de Confidentialité.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* CONTACT SECTION */}
      <section id="contact" className="py-24 text-center">
        <div className="mx-auto max-w-screen-lg px-4">
          <h2 className="mb-6 text-3xl font-bold md:text-4xl lg:text-5xl animate-on-scroll" style={{opacity: 0, transform: 'translateY(20px)'}}>
            Des questions? Pas de soucis!
          </h2>
          <p className="mx-auto mb-12 max-w-2xl text-xl text-muted-foreground animate-on-scroll" style={{opacity: 0, transform: 'translateY(20px)'}}>
            Vous souhaitez en savoir plus? N'hésitez pas à nous contacter! Nous serons
            ravis de vous montrer comment notre solution peut révolutionner vos journées.
          </p>
          <div className="animate-on-scroll" style={{opacity: 0, transform: 'translateY(20px)'}}>
            <Button asChild variant="primary" size="lg" className="rounded-full bg-violet-500 text-white hover:bg-green-100 hover:text-green-800 hover:border-green-300 shadow-md px-8">
              <Link to="/login">
                Contactez-nous
              </Link>
            </Button>
          </div>
        </div>
      </section>

      {/* FOOTER / A PROPOS */}
      <footer className="mt-8 border-t border-border bg-muted py-16 text-sm">
        <div className="mx-auto flex max-w-screen-xl flex-col gap-12 px-6 md:flex-row">
          {/* A PROPOS */}
          <div className="md:w-1/2">
            <div className="flex items-center gap-2 mb-6">
              <img src="/group-1.svg" alt="Corrige Logo" className="h-8 w-auto" />
              <span className="text-xl font-semibold">Corrige</span>
            </div>
            <h3 className="mb-4 text-lg font-bold">À PROPOS</h3>
            <p className="leading-relaxed text-muted-foreground text-base">
              Notre mission est de simplifier l'éducation en offrant des solutions
              de correction automatisées, rapides et fiables, permettant aux
              enseignants de se concentrer sur l'apprentissage de leurs élèves.
            </p>
            <div className="mt-6 flex space-x-4">
              <a href="#" className="text-muted-foreground hover:text-foreground transition-colors">
                <svg className="h-6 w-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                  <path fillRule="evenodd" d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z" clipRule="evenodd" />
                </svg>
              </a>
              <a href="#" className="text-muted-foreground hover:text-foreground transition-colors">
                <svg className="h-6 w-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                  <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
                </svg>
              </a>
              <a href="#" className="text-muted-foreground hover:text-foreground transition-colors">
                <svg className="h-6 w-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                  <path fillRule="evenodd" d="M12 2C6.477 2 2 6.484 2 12.017c0 4.425 2.865 8.18 6.839 9.504.5.092.682-.217.682-.483 0-.237-.008-.868-.013-1.703-2.782.605-3.369-1.343-3.369-1.343-.454-1.158-1.11-1.466-1.11-1.466-.908-.62.069-.608.069-.608 1.003.07 1.531 1.032 1.531 1.032.892 1.53 2.341 1.088 2.91.832.092-.647.35-1.088.636-1.338-2.22-.253-4.555-1.113-4.555-4.951 0-1.093.39-1.988 1.029-2.688-.103-.253-.446-1.272.098-2.65 0 0 .84-.27 2.75 1.026A9.564 9.564 0 0112 6.844c.85.004 1.705.115 2.504.337 1.909-1.296 2.747-1.027 2.747-1.027.546 1.379.202 2.398.1 2.651.64.7 1.028 1.595 1.028 2.688 0 3.848-2.339 4.695-4.566 4.943.359.309.678.92.678 1.855 0 1.338-.012 2.419-.012 2.747 0 .268.18.58.688.482A10.019 10.019 0 0022 12.017C22 6.484 17.522 2 12 2z" clipRule="evenodd" />
                </svg>
              </a>
            </div>
          </div>
          {/* NEWSLETTER */}
          <div className="md:w-1/2">
            <h4 className="mb-4 text-lg font-bold">
              ABONNEZ-VOUS À NOS DERNIÈRES NOUVELLES
            </h4>
            <p className="mb-4 text-muted-foreground">Restez informé des nouvelles fonctionnalités et des offres spéciales</p>
            <form className="flex w-full max-w-md items-center gap-2">
              <Input
                type="email"
                placeholder="Entrez votre adresse e-mail"
                className="w-full flex-1 rounded-full"
              />
              <Button type="submit" className="px-6 rounded-full">
                S'abonner
              </Button>
            </form>
            <div className="mt-8">
              <h5 className="mb-3 text-base font-semibold">Navigation</h5>
              <div className="flex flex-wrap gap-x-6 gap-y-2">
                <a href="#benefices" className="text-muted-foreground hover:text-foreground transition-colors">Bénéfices</a>
                <a href="#demo" className="text-muted-foreground hover:text-foreground transition-colors">Démo</a>
                <a href="#tarification" className="text-muted-foreground hover:text-foreground transition-colors">Tarification</a>
                <a href="#faq" className="text-muted-foreground hover:text-foreground transition-colors">FAQ</a>
                <a href="#contact" className="text-muted-foreground hover:text-foreground transition-colors">Contact</a>
                <Link to="/login" className="text-muted-foreground hover:text-foreground transition-colors">Se connecter</Link>
                <Link to="/privacy-policy" className="text-muted-foreground hover:text-foreground transition-colors">Politique de confidentialité</Link>
                <Link to="/terms-of-service" className="text-muted-foreground hover:text-foreground transition-colors">Conditions d'utilisation</Link>
              </div>
            </div>
          </div>
        </div>
        <div className="mx-auto mt-12 max-w-screen-xl px-4 text-center">
          <p className="text-muted-foreground">© {new Date().getFullYear()} Corrige. Tous droits réservés.</p>
        </div>
      </footer>

      {/* Scroll to top button */}
      <Button 
        onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})}
        className="fixed bottom-6 right-6 rounded-full p-3 shadow-md hover:shadow-lg"
        variant="outline"
        size="icon"
      >
        <svg className="h-6 w-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 10l7-7m0 0l7 7m-7-7v18" />
        </svg>
      </Button>

      {/* CSS for animations */}
      <style jsx>{`
        .animate-on-scroll {
          transition: opacity 0.8s ease-out, transform 0.8s ease-out;
        }
        .animate-visible {
          opacity: 1 !important;
          transform: translateY(0) !important;
        }
        .bg-grid-pattern {
          background-size: 20px 20px;
          background-image: linear-gradient(to right, rgba(129, 77, 242, 0.1) 1px, transparent 1px),
            linear-gradient(to bottom, rgba(129, 77, 242, 0.1) 1px, transparent 1px);
        }
      `}</style>
    </div>
  );
}

export default LandingPage;